






import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';

@Component
export default class ForbiddenPage extends Vue {
    @Inject(AuthServiceS) private authService!: AuthService;

    logout() {
        this.authService.logout();
    }
}
